// import React, { useRef, useEffect } from 'react';

// const LazyImage = ({ src , alt, className , onError }) => {
//   const imgRef = useRef();

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       (entries) => {
//         entries.forEach((entry) => {
//           if (entry.isIntersecting) {
//             // Load the image when it comes into view
//             imgRef.current.src = src;
//             observer.unobserve(imgRef.current);
//           }
//         });
//       },
//       { threshold: 0.5 } // Adjust the threshold as needed
//     );

//     observer.observe(imgRef.current);

//     // Cleanup observer on component unmount
//     return () => {
//       observer.disconnect();
//     };
//   }, [src]);

//   return <img className= {className} ref={imgRef} alt={alt} loading="lazy" onError={onError}/>;
// };

// export default LazyImage;

//el codigo de arriba me generaba un error 
// ERROR
// Cannot set properties of null (setting 'src')
// TypeError: Cannot set properties of null (setting 'src')
//     at http://localhost:3000/static/js/bundle.js:23307:30
//     at Array.forEach (<anonymous>)
//     at IntersectionObserver.threshold (http://localhost:3000/static/js/bundle.js:23304:15)
//para resolver incializo useRef(null) para que imgRef.current sea null al inicio y no undefined.

import React, { useRef, useEffect } from 'react';

const LazyImage = ({ src, alt, className, onError }) => {
  const imgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && imgRef.current) {
            imgRef.current.src = src;
            observer.unobserve(imgRef.current);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => observer.disconnect();
  }, [src]);

  return <img className={className} ref={imgRef} alt={alt} loading="lazy" onError={onError} />;
};

export default LazyImage;
