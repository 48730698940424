import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Card, Col, Form, Row, Button, FormGroup } from 'react-bootstrap';
import LazyImage from '../../../functions/LazyImage';
import apiFc from '../../../services/APIFc';
import { useSelector } from 'react-redux';
import CardGrupo from '../../Card Grupos/CardGrupo';
import ExtraLargeModal from '../../common/ExtraLargeModal';
import { AlertDialogInterface, SelectOption } from '../../common/Interfaces';
import Select from "react-select";
import { decryptData } from '../../../functions/cryptoUtils';


const CatalogoMarca = () => {
    const [queryParameters] = useSearchParams();
    const [marcaId, setMarcaId] = useState(queryParameters.get("marcaId"));
    const [articulosGrupo, setArticulosGrupo] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState<any>({});
    const [items, setItems] = useState([]);
    const [filtroLocal, setFiltroLocal] = useState('');
    const [showDetalle, setShowDetalle] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [filtro, setFiltro] = useState(queryParameters.get("filtro"));
    const [isMobile, setIsMobile] = useState(window.innerWidth < 500);
    const marcas = useSelector((store: any) => decryptData(store.principalreducer.marcas) || []);
    const [imageSrc, setImageSrc] = useState<any>()
    const [alertDialog, setAlertDialog] = useState<AlertDialogInterface>({
        id: "1",
        visible: false,
        severity: 'success',
        timeOut: 2000,
        cabecera: "Agregado!",
        texto: "el producto fue agregado.",
    });

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 500);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isMobile) {
            setImageSrc(`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Marcas/PortadaMarcas/${marcaId}_xs.jpg`);
        } else {
            setImageSrc(`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Marcas/PortadaMarcas/${marcaId}_xxl.jpg`);
        }
    }, [isMobile, marcaId]);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setMarcaId(queryParameters.get("marcaId"));
        fetchData();
    }, [queryParameters]);

    useEffect(() => {
        fetchData();
    }, [marcaId]);

    useEffect(() => {
        if (selectedGroup.articuloGrupoId > 0) {
            try {
                apiFc.get(`/articuloGrupos/${selectedGroup.articuloGrupoId}/articulos`).then(res => {
                    setArticulosGrupo(res.data);
                    setShowDetalle(true);
                });
            } catch (error) { }
        }
    }, [selectedGroup]);

    const fetchData = () => {
        if (marcaId !== null) {
            apiFc.get(`/web/articulos/marcas/${marcaId}/articulogrupos?page=0&rows=1000`)
                .then(res => {
                    setItems(res.data.content);
                    setFiltroLocal('');
                    window.scrollTo(0, 0);
                });
        }
    };

    const handleOpenModal = (articulo: any) => {
        setSelectedGroup(articulo);
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedGroup({});
    };

    const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        fetchData();
    };

    let marcasOpciones = (): SelectOption[] => {
        let marcasList: SelectOption[] = [];
        marcas.map((marcam: any) => {
            let opcion: SelectOption = { value: '', label: '' }
            opcion.value = marcam.id
            opcion.label = marcam.descripcion
            marcasList.push(opcion)
        });
        return marcasList;
    };

    return (
        <div>
            <ExtraLargeModal
                show={showModal}
                handleClose={handleCloseModal}
                selectedGroup={selectedGroup}
                alertDialog={alertDialog}
                // setAlertDialog={setAlertDialog}
                windowWidth={window.innerWidth}
            />
            {/* <!-- breadcrumb --> */}
            <div className="breadcrumb-header justify-content-between mg-b-30">
                <div className="left-content wd-100p portada-container">
                    <LazyImage
                        className="wd-100p ht-200"
                        src={imageSrc}
                        alt="Logo Marca"
                        onError={(e: any) => {
                            e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Marcas/PortadaMarcas/${marcaId}.png`
                        }}
                    />
                    <div className="portada-logo logo-overlay-marca">
                    <img
                        src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Marcas/${marcaId}.jpg`}
                        alt="Logo Marca"
                        className=""
                        onError={(e: any) => {
                            e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`
                        }}
                    />
                    </div>
                </div>
            </div>
            {/* <!-- /breadcrumb --> */}
            <Card>
                <Card.Body className="p-2">
                    <Col md={12} sm={9} xs={12}>
                        <Form onSubmit={handleSearch}>
                            <Row className="align-items-center">
                                <Col lg={8} md={8} sm={12} xs={12} className="mb-2">
                                    <div className="input-group">
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            placeholder="Buscar ..."
                                            onFocus={(e) => e.target.select()}
                                            onChange={(e: any) => setFiltroLocal(e.target.value)}
                                        />
                                        <Button variant="primary" type="submit" className='btn btn-primary mg-r-5'>
                                            Buscar
                                        </Button>
                                    </div>
                                </Col>
                                <Col lg={4} md={4} sm={12} xs={12} className="mb-2 ">
                                    <FormGroup className="form-group fix-combobox">
                                        {/* <Form.Label className="form-label">Marcas</Form.Label> */}
                                        <Select
                                            placeholder={"Seleccione una marca"}
                                            defaultValue={marcasOpciones().find((marca) => marca.value === marcaId || undefined)}
                                            options={marcasOpciones()}
                                            onChange={(marca: any) => {
                                                setMarcaId(marca.value);
                                                setFiltro(null)
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <p className="tx-12 tx-gray-500 mb-2 mg-b-10">
                                {`Busque entre los productos de la marca `}
                            </p>
                        </Form>
                    </Col>
                </Card.Body>
            </Card>

            <Col xl={12} lg={12} md={12}>
                <Row>
                    {items.filter((item: any) => item.articuloGrupoDescripcion.toLowerCase().includes(filtroLocal.toLowerCase()))
                        .map((articuloGrupo: any, i) => (
                            <Col sm={6} xl={4} xxl={3} lg={6} md={6} key={articuloGrupo.id}>
                                <CardGrupo articuloGrupo={articuloGrupo} key={articuloGrupo.id}
                                    onClick={() => {
                                        setSelectedGroup(articuloGrupo);
                                        handleOpenModal(articuloGrupo);
                                    }}
                                />
                            </Col>
                        ))}
                </Row>
            </Col>
        </div>
    );
};

export default CatalogoMarca;