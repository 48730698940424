import { Col, Row } from "react-bootstrap";
import { useEffect, useMemo, useState } from "react";
import CardMarca from "../Card Marca/CardMarca";
import { useSelector, useDispatch } from "react-redux";
import { decryptData } from "../../functions/cryptoUtils";
import APIFc from "../../services/APIFc";
import { setMarcas } from "../../redux/actions/principal";
import FcCarousel from "../FcCarousel/FcCarousel";

export default function ShowMarcasInicio() {
  const marcasRaw = useSelector((store: any) => store.principalreducer.marcas);
  const marcas = useMemo(() => decryptData(marcasRaw) || [], [marcasRaw]);
  const dispatch = useDispatch();
  console.log("marcasInicio")
  useEffect(() => {
    if (!marcasRaw || marcasRaw.length === 0) {
      APIFc.get(`/web/articulos/marcas`).then(res => {
        dispatch(setMarcas(res.data));
      });
    }
  }, [dispatch, marcasRaw]);

  // aca selecciono 5 marcas aleatorias intentando no modificar el array original
  const getRandomMarcas = (array: any[], count: number) => {
    const shuffled = [...array].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  //cambia cuando se actualizan las marcas
  const marcasFiltradas = useMemo(() => getRandomMarcas(marcas, 5), [marcas]);

  return (
    <div>
      <div className="mg-b-10 mg-t-30">
        <h1 className="display-6 tx-20 tx-bold"><strong>Marcas</strong></h1>
      </div>
      <Row>
        {marcasFiltradas.length > 0 && (
          <FcCarousel items={marcasFiltradas.map((marca: any) => (
            <Col xs={6} sm={6} xl={3} xxl={3} lg={4} md={4} className="mg-t-10" key={marca.id}>
              <CardMarca marca={marca} />
            </Col>
          ))} />
        )}
      </Row>
    </div>
  );
}