import React, { useEffect, useState } from "react";
import { Modal, Button, Table } from "react-bootstrap";

const BotonOfertasActivas = (props: any) => {
    const [show, setShow] = useState(false);

    useEffect(() => { }, [show]);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const ofertas = props.ofertas || [];

    if (!show) {
        return (
            <button className="btn btn-warning wd-100p btn-sm mt-2" onClick={handleShow}>Ofertas activas</button>
        );
    }
    
    return (<div>
        <button className="btn btn-warning wd-100p btn-sm mt-2" onClick={handleShow}>Ofertas activas</button>

        {/* Modal */}
        <Modal className="wd-80p d-flex m-auto"
            dialogClassName="modal-90w" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Ofertas Activas</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Detalle</th>
                            <th>Bonificación (%)</th>
                            <th>Precio con Descuento</th>
                            <th>Cantidad Mínima</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ofertas.map((oferta: any) => (
                            <tr key={oferta.idDescuento}>
                                <td>{oferta.detalleDescuento}</td>
                                <td className="text-end">{oferta.porcentajeBonificacionDescuento}%</td>
                                <td className="text-end">${oferta.precioUnitarioConDescuento.toFixed(2)}</td>
                                <td className="text-end">{oferta.cantidadMinimaDescuento}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    </div>)



};

export default BotonOfertasActivas;