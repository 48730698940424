import { Fragment, useEffect, useRef, useState } from "react";
import { Card, Col, Tabs, Tab, Table, Pagination, FormGroup, Row, Form, Button, OverlayTrigger, Tooltip, InputGroup, Modal, Popover, PopoverBody, Dropdown, Spinner, Breadcrumb, Badge, } from "react-bootstrap";
import Select, { ActionMeta, SingleValue } from "react-select";
import TextField from "@material-ui/core/TextField";
import { useSelector, useDispatch } from "react-redux";
import apiFc from "../../../../services/APIFc";
import { filterProduct } from "../../../../redux/actions/grid";
import Swal from "sweetalert2";
import CartelInformativo from "../../../common/CartelInformativo";
import { setPedidoActual, setPedidoActualRenglones, setPendientes, } from "../../../../redux/actions/pedido";
import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { isMobile } from "react-device-detect";
import DetalleArticulo from "../../../common/DetalleArticulo";
import { StockLevel, formatearNumero, formatearPrecio, getProductBadgeInfo, isUserLogued, } from "../../../../functions/Procedimientos";
import { Link, useNavigate } from "react-router-dom";
import ReactFacebookPixel from "react-facebook-pixel";
import { Typeahead, AsyncTypeahead, withAsync, } from "react-bootstrap-typeahead";
import InputCarritoCompra from "../../../common/InputCarritoCompra";
import { AlertDialogInterface } from "../../../common/Interfaces";
import { TablaDataBasica } from "../../../common/TablaDataBasica";
import Joyride from "react-joyride";
import { pasosPedidoActual } from "../../../common/TutorialPasos";
import { decryptData } from "../../../../functions/cryptoUtils";
import BarraBusquedaSugerida, { respuestaBusqueda, } from "../../../BarraBusqueda/BarraBusquedaSugerida";
import BotonOfertasActivas from "../../../common/BotonOfertaActiva";

var valTransporte: number = 0;
var valFechaRetiro: string = "";
var valComisionista: string = "";
var valFormaEnvio: string = "";
var valFormaVenta: string = "";
var valOrdenCompra: string = "";

const MiPedidoActual = () => {
  const navigate = useNavigate();
  //Informacion del cliente
  const user = useSelector((store: any) => decryptData(store.authreducer.user));

  //la busqueda la vamos a hacer localmente en la pantalla no vamos a usar redux -- Error pero unstable_ownerDocument, lo necesitamos
  const [showTutorial, setShowTutorial] = useState(false);

  //UseState utilizado para definir orden de los productos renglones del pedido
  const [ordenRenglonesPedido, setOrdenRenglonesPedido] = useState<{
    value: string;
    label: string;
  }>({
    value: "1",
    label: "Abcedario",
  });

  //transportes
  const transportesDisponibles = useSelector((store: any) => decryptData(store.pedidoreducer.transportesDisponibles));
  const condicionesVenta = useSelector((store: any) => decryptData(store.pedidoreducer.condicionesVenta));
  //const { transportesDisponibles, condicionesVenta } = useSelector((store: any) => store.pedidoreducer);
  const pedidoActualRenglones = useSelector((store: any) => decryptData(store.pedidoreducer.pedidoActualRenglones));

  const mostrarInformacion = useSelector((store: any) => decryptData(store.authreducer.mostrarInformacion));
  // console.log(pedidoActualRenglones)
  const [requiereFechaRetiro, setRequiereFechaRetiro] = useState<boolean>(false);
  const [requiereDetalleRetiro, setRequiereDetalleRetiro] = useState<boolean>(false);

  //productSearch lo utilizo para administrar el input del usuario en la guirlla de busqueda
  const [productSearch, setProductSearch] = useState<any>("");

  const dispatch = useDispatch();

  // console.log(transportesDisponibles)
  //Administrador de las tabs
  const [tabKey, setTabKey] = useState("tBuscador");

  // aqui en donde localmente pongo el valor de  gridPedidoArticulosBusqueda para dibujar en la tabla
  const [listaArticulo, setListaArticulo] = useState<any[]>();

  //Administrador de las tabs
  const [alertDialog, setAlertDialog] = useState<AlertDialogInterface>({
    id: "1",
    visible: false,
    severity: "success", // severity="error" / "warning" / "info" / "success"
    timeOut: 2000, // severity="error" / "warning" / "info" / "success"
    cabecera: "Agregado!",
    texto: "el producto fue agregado.",
  });

  const [fechaRetiroEditada, setFechaRetiroEditada] = useState(false);

  //Replico lo que hice en PDV para mostrar las sugerencias en caso de haber una falla en la busqueda original
  function buscarArticulos(filtro: string) {
    apiFc
      .get(`/web/articulos/consulta?filter=${filtro}`)
      .then((res) => {
        if (res.data.length !== 0) {
          // Si la respuesta no esta vacia, setear los valores
          setListaArticulo(res.data);
        } else {
          // Si la respuesta esta vacia, realizar el segundo llamado a la API buscando las sugerencias
          apiFc
            .get(`/web/articulos/consulta/sugeridos?filter=${filtro}`)
            .then((res) => {
              //Con esta funcion then espero la respuesta de la api
              if (res.data.length !== 0) {
                // si la respuesta no esta vacia, setear los valores
                setListaArticulo(res.data);
              } else {
                //si incluso la sugeencia no tiene resutados entonces beriamos mostrar cualquier cosa, agregalo en clickup
              }
            })
            .catch((err) => {
              //Si falla
              setAlertDialog({
                ...alertDialog,
                visible: true,
                cabecera: "Error buscando articulos",
                texto: "Ocurrió un error al buscar los artículos.",
                severity: "warning",
              });
            });
        }
      })
      .catch((err) => {
        setAlertDialog({
          ...alertDialog,
          visible: true,
          cabecera: "Error buscando articulos",
          texto: "Ocurrió un error al buscar los artículos.",
          severity: "warning",
        });
      });
  }

  useEffect(() => { }, [listaArticulo]);

  const [
    isLoadingArticulosGrupoSeleccionado,
    setIsLoadingArticulosGrupoSeleccionado,
  ] = useState(false);
  const [articulosGrupoSeleccionado, setArticulosGrupoSeleccionado] =
    useState<number>();

  useEffect(() => {
    if (articulosGrupoSeleccionado)
      apiFc
        .get(`/articuloGrupos/${articulosGrupoSeleccionado}/articulos`)
        .then((res) => {
          setListaArticulo(res.data);
          setIsLoadingArticulosGrupoSeleccionado(false);
        });
  }, [articulosGrupoSeleccionado]);

  //funcion para manejar el buscador delinput
  const handleInput = (event: any) => {
    if (event.code === "Enter") {
      buscarArticulos(event.target.value);
      event.target.blur();
    }
  };

  const containerRef = useRef<HTMLInputElement | null>(null);

  const handleClick = (event: any) => {
    buscarArticulos(containerRef?.current?.value || "");
    containerRef?.current?.blur();
  };

  useEffect(() => { }, [listaArticulo]);

  useEffect(() => {
    setAlertDialog({ ...alertDialog, visible: false });
    window.scrollTo(0, 0);
  }, [tabKey]);

  useEffect(() => { }, [alertDialog]);

  //si alguien disparo un cambio en la busqueda entonces refresco la pantalla,m pero antes asigno a Data el valor obtenido
  useEffect(() => { }, [pedidoActualRenglones, ordenRenglonesPedido]);

  //Funcion para mostrar al enviar pedido esto deberia ser una funcion general
  function Mensaje(props: any) {
    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
      props,
      ref
    ) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (
      event?: React.SyntheticEvent | Event,
      reason?: string
    ) => {
      if (reason === "clickaway") {
        return;
      }
      setAlertDialog({ ...alertDialog, visible: false });
    };
    return (
      <Snackbar
        open={alertDialog.visible}
        autoHideDuration={alertDialog.timeOut}
        onClose={handleClose}
        //Aca decido si es mobile muestro arriba el cartelito, sino abajo
        anchorOrigin={
          isMobile
            ? { vertical: "top", horizontal: "center" }
            : { vertical: "bottom", horizontal: "right" }
        }
      >
        <Alert
          onClose={handleClose}
          severity={alertDialog.severity == "success" ? "success" : "warning"}
          sx={{ width: "100%" }}
        >
          <strong>{alertDialog.cabecera} </strong> {alertDialog.texto}
        </Alert>
      </Snackbar>
    );
  }

  const eliminarRenglon = (item: any, e: any) => {
    apiFc
      .post(`/web/pedido/actual/renglon/${item.articuloCodigo}?accion=-1`, `{}`)
      .then((res) => {
        apiFc
          .get(`/web/pedido/actual/renglon`)
          .then((res: any) => {
            setAlertDialog({
              ...alertDialog,
              visible: true,
              cabecera: "Eliminado con exito",
              texto: `El articulo ${item.articuloCodigo}`,
              severity: "success",
            });
            dispatch(setPedidoActualRenglones(res.data));
          })
          .catch((err) => {
            dispatch(setPedidoActualRenglones(null));
          });
      })
      .catch((res) => { });
  };

  async function tratarPendiente(pendiente: any, accion: number) {
    await apiFc
      .post(
        `/web/pedido/pendientes?action=${accion}`,
        JSON.stringify(pendiente)
      )
      .then((res) => {
        setAlertDialog({
          ...alertDialog,
          visible: true,
          cabecera: "Pendiente procesado con exito",
          texto: `El articulo ${pendiente.articuloPendientePK.articuloCodigo}`,
          severity: "success",
        });
        apiFc
          .get(`/web/pedido/pendientes`)
          .then((res) => {
            dispatch(setPendientes(res.data));
          })
          .catch((err) => {
            dispatch(setPendientes([]));
          });
        apiFc
          .get(`/web/pedido/actual`)
          .then((res) => {
            dispatch(setPedidoActual(res.data));
            apiFc
              .get(`/web/pedido/actual/renglon`)

              .then((res) => {
                dispatch(setPedidoActualRenglones(res.data));
              })
              .catch((err) => {
                dispatch(setPedidoActualRenglones(null));
              });
          })
          .catch((err) => {
            dispatch(setPedidoActual(null));
          });
      });
  }

  const [showDetalleArticulo, setShowDetalleArticulo] = useState<{
    visible: boolean;
    codArticulo: string;
    fullScreen: string;
  }>();
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const handleItemsPerPageChange = (
    newValue: SingleValue<{ value: number; label: string }>,
    actionMeta: ActionMeta<{ value: number; label: string }>
  ) => {
    if (newValue) {
      setPageSize(newValue.value);
      setCurrentPage(1); // Resetear a la primera página cuando se cambia el tamaño de la página
    }
  };
  const handleClickPage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const selectedItems = pedidoActualRenglones.slice(startIndex, endIndex);

  const totalItems = pedidoActualRenglones.length;
  const totalPages = Math.ceil(totalItems / pageSize);

  // Número de páginas a mostrar
  const pagesToShow = 5;

  // Calcular el rango de páginas para mostrar
  let startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
  let endPage = Math.min(totalPages, startPage + pagesToShow - 1);

  // Ajustar el rango para mostrar exactamente `pagesToShow` páginas
  if (endPage - startPage + 1 < pagesToShow) {
    startPage = Math.max(1, endPage - pagesToShow + 1);
  }

  const pageSizeOptions = [10, 25, 50].map((size) => ({
    value: size,
    label: `Mostrar ${size}`,
  }));

  useEffect(() => { }, [showDetalleArticulo]);

  const handleClickReemplazo = async (codArticulo: string) => {
    try {
      const response = await apiFc.get(
        `/web/articulos/${codArticulo}/remplazos`
      );
      if (response.data.length > 0) {
        setShowDetalle(true);
        setListaArticulo(response.data);
      } else {
        setAlertDialog({
          ...alertDialog,
          visible: true,
          cabecera: "No hay articulos para reemplazar",
          texto: ` `,
          severity: "warning",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  function handleShowDetalleArticulo(articuloCodigo: string) {
    setShowDetalleArticulo({
      ...showDetalleArticulo,
      visible: true,
      codArticulo: articuloCodigo,
      fullScreen: "true",
    });
  }

  function renderCuerpoTablaBuscador(): import("react").ReactNode {
    if (listaArticulo && listaArticulo.length != 0) {
      //  console.log(listaArticulo)
      return listaArticulo.map((item: any, index: any) => (
        <tr key={item.articuloCodigo}>
          {isMobile ? (
            <th scope="row">{`${item.articuloCodigo} - ${item.articuloDescripcion}`}</th>
          ) : (
            <>
              <th scope="row">{item.articuloCodigo}</th>
              <td>{item.articuloDescripcion}</td>
            </>
          )}
          <td className="text-center text-lg text-medium font-weight-bold  tx-15 paso6">
            {item.articuloUnidadVenta}{" "}
            {item.articuloCantidadMultiplo != 0 && (
              <Button
                variant=""
                className="btn btn-warning "
                type="button"
                onClick={(e) => {
                  CartelInformativo(
                    "El producto posee parametros de pedido!",
                    `Minimo permitido: ${item.articuloCantidadMinSugerida} <br> Maximo Recomendado: ${item.articuloCantidadMaxSugerida} <br> Multiplo: ${item.articuloCantidadMultiplo}`
                  );
                }}
              >
                <i className="fa fa-info "></i>
              </Button>
            )}
          </td>

          <td className="paso5">
            {formatearPrecio({ valor: parseFloat(item.articuloPrecio) })}
          </td>
          {/* <td className="paso6"> {formatearPrecio({ valor: parseFloat((user.clienteConfiguracionMostrarIVA) ? item.articuloPrecioVentaSug1 * (1 + (item.articuloTasaIVA / 100)) : item.articuloPrecioVentaSug1) })}</td>
            <td className="paso7">{formatearPrecio({ valor: parseFloat((user.clienteConfiguracionMostrarIVA) ? item.articuloPrecioVentaSug2 * (1 + (item.articuloTasaIVA / 100)) : item.articuloPrecioVentaSug2) })}</td> */}
          <td className="paso8">
            {
              <StockLevel
                stock={item.articuloStock}
                stockMin={item.articuloStockMin}
                stockMax={item.articuloStockMax}
              ></StockLevel>
            }
          </td>
          <td className="paso9">
            <InputCarritoCompra
              articulo={item}
              origen={"PuestoDeVenta"}
              onEvent={(e: AlertDialogInterface) => setAlertDialog(e)}
            />
          </td>
          <td>
            <Button
              variant=""
              className="btn btn-primary"
              onClick={() => handleShowDetalleArticulo(item.articuloCodigo)}
            >
              Detalle <i className="fa fa-search"> </i>
            </Button>
          </td>
        </tr>
      ));
    }
    return (
      <div
        style={{ height: "500px", alignItems: "center", position: "relative" }}
      >
        <span
          style={{
            position: "absolute",
            bottom: "50%",
            left: "50%",
            whiteSpace: "nowrap",
          }}
        >{`No hay resultados`}</span>
      </div>
    );
  }

  function renderCuerpoRenglonesPedido(): React.ReactNode {
    return (
      <tbody>
        {selectedItems && (
          <Fragment>
            {selectedItems.map((pedidoRenglon: any) => (
              <Fragment key={pedidoRenglon.pedidoRenglonPK.pedidoNroRenglon}>
                <tr>
                  <td>
                    <div className="media">
                      <div className="card-aside-img">
                        <img
                          src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/${pedidoRenglon.articuloImagen}`}
                          className="h-60 w-60"
                          alt=""
                        />
                      </div>
                      <div className="media-body">
                        <div className="card-item-desc mt-0">
                          <h6 className="font-weight-semibold mt-0 text-uppercase">
                            {pedidoRenglon.articuloDescripcion}
                          </h6>
                          <dl className="card-item-desc-1">
                            <dt>{`Codigo:       `}</dt>
                            <dd>{`       ${pedidoRenglon.articuloCodigo}`}</dd>
                          </dl>
                          <dl className="card-item-desc-1">
                            <dt>{`U.v:     `}</dt>
                            <dd>{`   Paquete`}</dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="text-webkit-center paso8">
                    <InputCarritoCompra
                      articulo={pedidoRenglon.articulo}
                      origen={"PedidoActual"}
                      onEvent={(e: AlertDialogInterface) => setAlertDialog(e)}
                    />
                  </td>
                  <td>
                    { 
                      pedidoRenglon.pedidoRenglonPrecioRegularUnitario != pedidoRenglon.pedidoRenglonPrecioUnitario
                        ?
                        <div className="text-end">
                          <div>
                            <strong className="text-end text-muted text-decoration-line-through">{formatearPrecio({
                              valor: parseFloat(
                                (
                                  pedidoRenglon.pedidoRenglonPrecioRegularUnitario
                                ).toFixed(2)
                              ),
                            })}</strong>
                          </div>
                          <div>
                            <strong className="text-end text-success text-lg text-medium font-weight-bold tx-15">{formatearPrecio({
                              valor: parseFloat(
                                (
                                  pedidoRenglon.pedidoRenglonPrecioUnitario
                                ).toFixed(2)
                              ),
                            })}</strong>
                          </div>
                        </div>
                        :
                        <div className="text-end text-lg text-medium font-weight-bold tx-15">
                          {formatearPrecio({
                            valor: parseFloat(
                              (
                                pedidoRenglon.pedidoRenglonPrecioUnitario
                              ).toFixed(2)
                            ),
                          })}
                        </div>
                    }
                    <>
                    {pedidoRenglon.pedidoRenglonDescuentosAplicables?.length > 0 &&
                      <BotonOfertasActivas ofertas={pedidoRenglon.pedidoRenglonDescuentosAplicables} />
                    }
                    </>
                  </td>
                  <td className="text-center font-weight-bold  tx-15 paso13">
                    {renderStockCumplible(
                      pedidoRenglon.articuloStock,
                      pedidoRenglon.pedidoRenglonCantidad,
                      pedidoRenglon.articuloCodigo
                    )}
                  </td>
                  <td>
                    <div className="text-end text-lg text-medium font-weight-bold tx-15">
                      {formatearPrecio({
                        valor: parseFloat(
                          (
                            pedidoRenglon.pedidoRenglonPrecioUnitario *
                            pedidoRenglon.pedidoRenglonCantidad
                          ).toFixed(2)
                        ),
                      })}
                    </div>
                  </td>
                  <td className="text-center">
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Eliminar item</Tooltip>}
                    >
                      <div
                        className="btn btn-sm btn-danger-light"
                        onClick={(e) => eliminarRenglon(pedidoRenglon, e)}
                      >
                        <i className="fe fe-trash"></i>
                      </div>
                    </OverlayTrigger>
                  </td>
                </tr>
              </Fragment>
            ))}
          </Fragment>
        )}
      </tbody>
    );
  }

  function renderStockCumplible(
    articuloStock: number,
    pedidoRenglonCantidad: number,
    codArticulo: string
  ): React.ReactNode {
    if (articuloStock > pedidoRenglonCantidad * 1.5)
      return (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip className="hover-fix">Cumplible Total</Tooltip>}
        >
          <div className=" wd-2 bg-success">CT</div>
        </OverlayTrigger>
      );

    if (articuloStock >= pedidoRenglonCantidad) {
      return (
        <div>
          <div className="bg-warning mg-b-5">CR {/* Cumplible riesgoso */}</div>
          <div>
            <Button
              className="btn btn-primary btn-sm rounded-11 me-2 wd-100p"
              onClick={() => handleClickReemplazo(codArticulo)}
            >
              Reemplazo
            </Button>
          </div>
        </div>
      );
    }

    if (articuloStock === 0) {
      return (
        <div>
          <div className="wd-2p bg-danger mg-b-5">SS {/* Sin stock */}</div>
          <div>
            <Button
              className="btn btn-primary btn-sm rounded-11 me-2 wd-100p"
              onClick={() => handleClickReemplazo(codArticulo)}
            >
              Reemplazo
            </Button>
          </div>
        </div>
      );
    }

    if (articuloStock < pedidoRenglonCantidad) {
      return (
        <div>
          <div className="bg-orange mg-b-5">CP {/* Cumplible parcial */}</div>
          <div>
            <Button
              className="btn btn-primary btn-sm rounded-11 me-2 wd-100p"
              onClick={() => handleClickReemplazo(codArticulo)}
            >
              Reemplazo
            </Button>
          </div>
        </div>
      );
    }

    return <div className=" bg-indigo">Error</div>;
  }

  function listTransportesDispon(): any[] {
    let list: any[] = [];
    transportesDisponibles
      .filter((transporte: any) => transporte.transporteHabilitado)
      .map((transporte: any, index: any) => {
        list.push({
          value: transporte.transporteID,
          label: (
            <div>
              <i className="fa fa-truck"></i>
              <a> {transporte.transporteNombre}</a>
            </div>
          ),
        });
      });

    return list;
  }

  function eleccionTransporte(event: any): any {
    setRequiereFechaRetiro(event.value == 81 || event.value == 100);
    setRequiereDetalleRetiro(event.value == 81);
    valTransporte = event.value;
  }

  const [showDetalle, setShowDetalle] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<any>({});

  function extraLargeModal() {
    const handleClose = () => {
      setShowDetalle(false);
      setSelectedGroup({});
    };
    return (
      <>
        <Modal
          dialogClassName="modal-90w"
          show={showDetalle}
          aria-labelledby="example-modal-sizes-title-xl"
        >
          {alertDialog.visible && <Mensaje></Mensaje>}
          <Modal.Header>
            <Modal.Title>{"articuloDescripcion"}</Modal.Title>
            <Button onClick={handleClose} className="btn-close" variant="">
              x
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="product-details table-responsive text-nowrap ">
              <Table className="table table-bordered table-hover mb-0 text-nowrap">
                <thead>
                  <tr>
                    <th className="text-start">Producto</th>
                    {isUserLogued() ? (
                      <>
                        <th className="w-150">Cantidad</th>
                        {user?.clienteConfiguracionMostrarCos ? (
                          <th>Costo</th>
                        ) : null}
                        <th>Sug 1</th>
                        <th>Sug 2</th>
                        <th>Stock</th>
                        {/* <th>Informacion</th> */}
                      </>
                    ) : (
                      <></>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {listaArticulo && (
                    <Fragment>
                      {listaArticulo.map((articulo: any) => {
                        // const articuloCodigo = articulo.articuloCodigo;
                        let { badgeBg, badgeText, badgeClassName } =
                          getProductBadgeInfo(articulo);
                        return (
                          <Fragment key={articulo.articuloCodigo}>
                            <tr>
                              <td
                              // open={open}
                              //onClick={handleClose}
                              >
                                <div className="media">
                                  <div className="card-aside-img">
                                    {articulo.articuloImagenGXI !== null ? (
                                      <>
                                        <img
                                          className="pic-1"
                                          src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/${articulo.articuloImagenV2}`}
                                          alt="product"
                                          onError={(e: any) => {
                                            e.target.onerror = null;
                                            e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/${articulo.articuloImagenGXI}`;
                                            e.target.onerror = (err: any) => {
                                              err.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`;
                                            };
                                          }}
                                        />
                                      </>
                                    ) : articulo.articuloGrupoId !== null &&
                                      articulo.articuloGrupoId !== undefined &&
                                      articulo.articuloGrupoId
                                        ?.articuloImagenGXI !== null ? (
                                      <>
                                        <img
                                          className=" pic-1"
                                          src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/${articulo.articuloGrupoId.articuloGrupoImagenGXI}`}
                                          alt="product"
                                          onError={(e: any) => {
                                            e.target.src = `https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`;
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          className=" pic-1"
                                          src={`https://ferretera-img-web-prod.s3.sa-east-1.amazonaws.com/public/img/Imagenes/Grupos/noimg.png`}
                                          alt="product"
                                        />
                                      </>
                                    )}
                                  </div>
                                  <div className="media-body">
                                    <div className="card-item-desc mt-0">
                                      <h6 className="font-weight-semibold mt-0 text-uppercase">
                                        {articulo.articuloDescripcion}
                                      </h6>
                                      <dl className="card-item-desc-1">
                                        <dt>{`Codigo:       `}</dt>
                                        <dd>{`       ${articulo.articuloCodigo}`}</dd>
                                      </dl>
                                      <dl className="card-item-desc-1">
                                        <dt>{`U.v:     `}</dt>
                                        <dd>{` ${articulo.articuloUnidadVenta}`}</dd>
                                        {/* <dd>{item.colors}</dd> */}
                                      </dl>
                                      <dl className="card-item-desc-1">
                                        <dt>
                                          <Badge
                                            bg={badgeBg}
                                            className={badgeClassName}
                                          >
                                            {badgeText}
                                          </Badge>
                                        </dt>
                                        {/* <dd>{item.colors}</dd> */}
                                      </dl>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              {isUserLogued() ? (
                                <>
                                  <td className="text-webkit-center">
                                    <InputCarritoCompra
                                      articulo={articulo}
                                      origen="grupoArticulo"
                                      onEvent={(e: any) => setAlertDialog(e)}
                                    />
                                  </td>
                                  {user?.clienteConfiguracionMostrarCos ? (
                                    <td className="text-end text-lg text-medium font-weight-bold tx-15">
                                      {articulo.articuloPrecio}
                                    </td>
                                  ) : null}
                                  <td className="text-end text-lg text-medium font-weight-bold tx-15">
                                    {user?.clienteConfiguracionMostrarIVA
                                      ? (
                                        articulo.articuloPrecioVentaSug1 *
                                        (1 + articulo.articuloTasaIVA / 100)
                                      ).toFixed(2)
                                      : articulo.articuloPrecioVentaSug1}
                                  </td>
                                  <td className="text-end text-lg text-medium font-weight-bold tx-15">
                                    {user?.clienteConfiguracionMostrarIVA
                                      ? (
                                        articulo.articuloPrecioVentaSug2 *
                                        (1 + articulo.articuloTasaIVA / 100)
                                      ).toFixed(2)
                                      : articulo.articuloPrecioVentaSug2}
                                  </td>
                                  <td className="text-center font-weight-bold tx-15">
                                    <StockLevel
                                      stock={articulo.articuloStock}
                                      stockMin={articulo.articuloStockMin}
                                      stockMax={articulo.articuloStockMax}
                                    ></StockLevel>
                                  </td>
                                  {/* <td>
                                        {articulo.articuloUnidadVenta}{" "}
                                        {articulo.articuloCantidadMultiplo !== 0 && (
                                          <Button
                                            variant=""
                                            className="btn btn-warning paso4"
                                            type="button"
                                            onClick={handleOpenModal}
                                          >
                                            <i className="fa fa-info"></i>
                                          </Button>
                                        )}
                                        <Modal show={showModal}
                                          onHide={handleCloseModal}
                                        >
                                          <Modal.Header closeButton>
                                            <Modal.Header className='wd-100p'>
                                              <Modal.Title>Informacion</Modal.Title>
                                              <Button
                                                onClick={handleClose}
                                                className="btn-close"
                                                variant=""
                                              >
                                                x
                                              </Button>
                                            </Modal.Header>
                                          </Modal.Header>
                                          <Modal.Body>
                                             <TablaDetalle /> 
                                          </Modal.Body>
                                          <Modal.Footer>
                                            <Button variant="secondary"
                                              onClick={handleCloseModal}
                                            >
                                              Salir
                                            </Button>
                                          </Modal.Footer>
                                        </Modal>
                                      </td> */}
                                </>
                              ) : (
                                <></>
                              )}
                            </tr>
                          </Fragment>
                        );
                      })}
                    </Fragment>
                  )}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Salir
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  const refCondicionVenta = useRef(null);

  function listCondicionesVenta(): any[] {
    let list: any[] = [];
    condicionesVenta.map((condicion: any, index: any) => {
      list.push({
        value: condicion.condicionVentaID,
        label: condicion.condicionVentaDesc,
      });
    });
    return list;
  }

  const refFormaEnvio = useRef(null);

  function listFormaEnvio(): any[] {
    let list: any[] = [];
    list.push(
      {
        value: "1",
        label: (
          <div>
            <i className="far fa-address-book"></i>
            <a> 100 - 0</a>
          </div>
        ),
      },
      {
        value: "2",
        label: (
          <div>
            <i className="fa fa-adjust"></i>
            <a> 50 - 50</a>
          </div>
        ),
      }
    );
    if (user.condicionVentaPruebaHabilitado > 0)
      list.push({
        value: "4",
        label: (
          <div>
            <i className="fa fa-address-book"></i>
            <a> 0 - 100</a>
          </div>
        ),
      });
    return list;
  }

  //Primaryalert
  function Primaryalert() {
    Swal.fire({
      title: "Excelente!",
      icon: "success",
      allowOutsideClick: false,
      confirmButtonText: "Volver al inicio",
      cancelButtonColor: "#38cab3",
      text: "Su pedido fue enviado correctamente!",
    }).then((result) => {
      let path = `${process.env.PUBLIC_URL}/principal/inicio`;
      navigate(path);
    });
  }

  function enviarPedido(): any {
    // Verificar si el cliente es de alta temprana
    if (user.clienteEsAltaTemprana === true) {
      setAlertDialog({
        ...alertDialog,
        visible: true,
        cabecera: "Error enviando pedido",
        texto:
          "No puede enviar el pedido dada su condición de alta temprana. Comuníquese con el vendedor para que se le habilite el envío de pedido.",
        severity: "warning",
      });
      return;
    }

    // Verificar si el campo de fecha de retiro ha sido editado y cumple con los requisitos
    if (valTransporte === 100 && !valFechaRetiro) {
      setAlertDialog({
        ...alertDialog,
        visible: true,
        cabecera: "Error enviando pedido",
        texto:
          "Debe seleccionar una fecha de retiro que cumpla con los requisitos.",
        severity: "warning",
      });
      return;
    }

    if (valTransporte === 100) {
      const selectedDate = new Date(valFechaRetiro);
      const currentDate = new Date();

      // Verificar si el día seleccionado es sábado o domingo
      if (selectedDate.getDay() === 6 || selectedDate.getDay() === 0) {
        setAlertDialog({
          ...alertDialog,
          visible: true,
          cabecera: "No puedes seleccionar un fin de semana.",
          severity: "warning",
        });
        return;
      }

      // Verificar si el día seleccionado es lunes y el día actual es viernes
      if (selectedDate.getDay() === 1 && currentDate.getDay() === 5) {
        setAlertDialog({
          ...alertDialog,
          visible: true,
          cabecera:
            "No puedes seleccionar una fecha de retiro que sea menor a 48 horas hábiles.",
          severity: "warning",
        });
        return;
      }

      // Calcular la fecha mínima permitida para realizar un pedido
      const minOrderDate = new Date(
        currentDate.getTime() + 48 * 60 * 60 * 1000
      );

      // Verificar si la fecha seleccionada está dentro de las próximas 48 horas
      if (selectedDate < minOrderDate) {
        setAlertDialog({
          ...alertDialog,
          visible: true,
          cabecera:
            "No puedes seleccionar una fecha de retiro que sea menor a 48 horas hábiles.",
          severity: "warning",
        });
        return;
      }

      // Verificar si la hora seleccionada está fuera del rango de 7:00 a 16:30 hs (horario de atención actual)
      const selectedHours = selectedDate.getHours();
      const selectedMinutes = selectedDate.getMinutes();
      if (
        selectedHours < 7 ||
        (selectedHours === 16 && selectedMinutes > 30) ||
        selectedHours > 16
      ) {
        setAlertDialog({
          ...alertDialog,
          visible: true,
          cabecera:
            "No puedes seleccionar una hora fuera del rango de 7:00 a 16:30 hs.",
          severity: "warning",
        });
        return;
      }
    }

    // Envío del pedido si todo está correcto
    apiFc
      .post(
        `/web/pedido/actual/cierre/finalizar?transporte=${valTransporte}&fechaRetiro=${valFechaRetiro}&detalleComisionista=${valComisionista}&formaEnvio=${valFormaEnvio}&condVenta=${valFormaVenta}&ordCompr=${valOrdenCompra}`,
        "{}"
      )
      .then((res) => {
        setAlertDialog({
          ...alertDialog,
          visible: true,
          cabecera: "Pedido enviado con éxito",
          texto: "",
          severity: "success",
        });
        dispatch(setPedidoActual(null));
        Primaryalert();
      })
      .catch((res) => {
        setAlertDialog({
          ...alertDialog,
          visible: true,
          cabecera: "Error enviando pedido",
          texto: res.message,
          severity: "warning",
        });
      });
  }

  //   function enviarPedido(): any {
  //     if(user.clienteEsAltaTemprana === true)
  //     {}
  //     else {
  //     apiFc.post(`/web/pedido/actual/cierre/finalizar` +
  //       `?transporte=${valTransporte}` +
  //       `&fechaRetiro=${valFechaRetiro}` +
  //       `&detalleComisionista=${valComisionista}` +
  //       `&formaEnvio=${valFormaEnvio}` +
  //       `&condVenta=${valFormaVenta}` +
  //       `&ordCompr=${valOrdenCompra}`, "{}").then(res => {
  //         //setAlertDialog({ ...alertDialog, visible: true, cabecera: "Pedido enviado con exito", texto: ``, severity: "success" })
  //         dispatch(setPedidoActual(null))
  //         Primaryalert()
  //       }).catch(res => {
  //         setAlertDialog({ ...alertDialog, visible: true, cabecera: "Error enviando pedido", texto: `${res.message}`, severity: "warning" })
  //       });
  // }
  //   }

  function importeTotal(): React.ReactNode {
    let total: number = 0;
    pedidoActualRenglones.map(
      (pedidoRenglon: any) =>
      (total +=
        pedidoRenglon.pedidoRenglonPrecioUnitario *
        pedidoRenglon.pedidoRenglonCantidad)
    );
    return total.toFixed(2);
  }

  /**
   * Pendientes
   */
  const pendientes = useSelector((store: any) =>
    decryptData(store.pedidoreducer.pendientes)
  );
  const pendienteColumns: any = [
    {
      Header: "Codigo",
      accessor: "articuloPendientePK.articuloCodigo",
      className: "wd-5p text-center borderrigth",
      rowClassName: "text-end",
    },
    {
      Header: "Descripcion",
      accessor: "articulo.articuloDescripcion",
      className: "wd-60p text-center borderrigth",
      rowClassName: "",
    },
    {
      Header: "Pendiente",
      accessor: (pendiente: any) =>
        formatearNumero({
          valor: pendiente?.articuloPendienteCantOrigen,
          style: "decimal",
        }), //"articuloPendienteCantOrigen",
      className: "wd-5p  text-center borderrigth",
      rowClassName: "text-end",
    },
    {
      Header: "Stock",
      accessor: (pendiente: any) => (
        <StockLevel
          stock={pendiente.articulo.articuloStock}
          stockMin={pendiente.articulo.articuloStockMin}
          stockMax={pendiente.articulo.articuloStockMax}
        ></StockLevel>
      ), //"articuloPendienteCantOrigen",
      className: "wd-5p  text-center borderrigth",
      rowClassName: "text-center",
    },
    {
      Header: "Accion",
      accessor: (pendiente: any) => {
        return (
          <span className="paso16">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Repedir</Tooltip>}
            >
              <Button
                className="btn btn-primary btn-sm rounded-11 me-2"
                onClick={(e: any) => {
                  e.preventDefault();
                  tratarPendiente(pendiente, 10);
                }}
              >
                <i>
                  <svg
                    className="table-edit"
                    xmlns="http://www.w3.org/2000/svg"
                    height="20"
                    viewBox="0 0 24 24"
                    width="16"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM5.92 19H5v-.92l9.06-9.06.92.92L5.92 19zM20.71 5.63l-2.34-2.34c-.2-.2-.45-.29-.71-.29s-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41z" />
                  </svg>
                </i>
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Eliminar</Tooltip>}
            >
              <Button
                className="btn btn-primary btn-sm rounded-11 me-2"
                onClick={(e: any) => {
                  e.preventDefault();
                  tratarPendiente(pendiente, 14);
                }}
              >
                <i>
                  <svg
                    className="table-delete"
                    xmlns="http://www.w3.org/2000/svg"
                    height="20"
                    viewBox="0 0 24 24"
                    width="16"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4h-3.5z" />
                  </svg>
                </i>
              </Button>
            </OverlayTrigger>
          </span>
        );
      },
      className: "wd-10p  text-center borderrigth ",
      rowClassName: "text-center",
    },
  ];
  const handleJoyrideCallback = (data: any) => {
    const { action, index, status, type } = data;
    if (index === 8) setTabKey("tRenglones");
    if (index === 13) setTabKey("tPendientes");
    if (index === 16) setTabKey("tEnviar");
    if (index === 3) setTabKey("tBuscador");
    setTimeout(() => {
      setProductSearch("pala");
    }, 5000);
  };

  return (
    <div>
      <Joyride
        steps={pasosPedidoActual}
        callback={handleJoyrideCallback}
        continuous={true}
        run={showTutorial}
        locale={{
          back: "Atras",
          close: "Cerrar",
          last: "Ultimo",
          next: "Siguente",
          open: "Abrir",
          skip: "Saltear",
        }}
        spotlightClicks={false} //te deja o no hacer click en los resaltado
        showProgress={true}
        showSkipButton={true}
      />
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Pedido actual
          </span>
          <>{extraLargeModal()}</>
          {alertDialog.visible && <Mensaje></Mensaje>}
          {showDetalleArticulo?.visible && (
            <DetalleArticulo
              visible={showDetalleArticulo.visible}
              codArticulo={showDetalleArticulo.codArticulo}
              onHandleShow={() =>
                setShowDetalleArticulo({
                  ...showDetalleArticulo,
                  visible: false,
                })
              }
            />
          )}
        </div>
        <div className="justify-content-center mt-2">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item
              className="breadcrumb-item btn-ayuda "
              active
              aria-current="page"
              onClick={() => setShowTutorial(!showTutorial)}
            >
              Ayuda
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {/* <!-- /breadcrumb --> */}
      <div className="panel panel-primary tabs-style-2">
        <div className=" tab-menu-heading">
          <div className="tabs-menu1">
            <Card>
              {/* <!-- Tabs --> */}
              <Tabs
                activeKey={tabKey}
                defaultActiveKey="tBuscador"
                className=" panel-tabs main-nav-line paso1 paso9 paso14 paso17"
                onSelect={(k: any) => setTabKey(k)}
              >
                <Tab eventKey="tBuscador" title="Buscador">
                  <Card>
                    <Card.Header className=" pb-0 paso1">
                      <div className="d-flex justify-content-between">
                        <h4 className="card-title mg-b-0">Buscar productos</h4>
                      </div>
                      {mostrarInformacion && (

<p className="tx-12 tx-gray-500 mb-2">
{`El buscador buscará los primeros 20 productos. Utilice el punto (.) para buscar por código. Ejemplo: coloque .252 para buscar "BALDE PLASTICO VOSS2000 ALBAÑIL MANIJA INYECTADA"`}
</p>
                        // <p className="tx-12 tx-gray-500 mb-2">
                        //   {`El buscador buscará los primeros 20 productos. Utilice el punto (.) para buscar por código.`}
                        // </p>
                      )}



                      <p className="tx-8 tx-gray-500 mb-2"></p>
                      <div className="input">
                        <BarraBusquedaSugerida
                          classNameContainer="input wd-100p"
                          classNameInput="form-control "
                          onSearch={(busqueda: respuestaBusqueda) => {
                            if (busqueda.type === 1) {
                              buscarArticulos(busqueda.value);
                            } else if (busqueda.type === 2) {
                              setIsLoadingArticulosGrupoSeleccionado(true);
                              setArticulosGrupoSeleccionado(busqueda.value);
                            }
                          }}
                        />
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <div className="table-responsive">
                        <Table className="table mg-b-0 text-md-wrap paso4">
                          <thead>
                            <tr className="paso5">
                              {isMobile ? (
                                <th>Descripcion</th>
                              ) : (
                                <>
                                  <th>Codigo</th>
                                  <th>Descripcion</th>
                                </>
                              )}
                              <th>U.venta</th>
                              {/* aca sacamos el sug1 sug2 porque es la tabla del pedido actual */}
                              {/* {user.clienteConfiguracionMostrarCos && <th>Precio</th>} */}
                              <th>Costo</th>
                              {/* <th>Sug1</th>
                              <th>Sug2</th> */}
                              <th>Stock</th>
                              <th className="text-center">Cantidad</th>
                              <th>Mas detalle</th>
                            </tr>
                          </thead>
                          <tbody className="paso5 ">
                            {renderCuerpoTablaBuscador()}
                          </tbody>
                        </Table>
                      </div>
                    </Card.Body>
                  </Card>
                </Tab>
                {/* TODO
                <Tab eventKey="tPendientes" title="Pendientes">
                  <Card>
                    <Card.Body>
                    </Card.Body>
                  </Card>
                </Tab> */}
                <Tab eventKey="tRenglones" title="Renglones Pedidos">
                  {/* <!-- row opened --> */}
                  <Row>
                    <Col>
                      <Card>
                        <Card.Body>
                          <div className="d-flex">
                            {/* <!-- Shopping Cart--> */}
                            <Select
                              className="input-fix paso11 mg-r-10"
                              defaultValue={ordenRenglonesPedido}
                              options={[
                                {
                                  value: "1",
                                  label: "Abcedario",
                                },
                                {
                                  value: "2",
                                  label: "Ultimo agregado",
                                },
                              ]}
                              onChange={(ordenTipo: any) => {
                                if (ordenTipo.value === "1") {
                                  //Ordenado por nro renglon
                                  pedidoActualRenglones?.sort(
                                    (r1: any, r2: any) =>
                                      r1.articuloDescripcion >
                                        r2.articuloDescripcion
                                        ? 1
                                        : -1
                                  );
                                }
                                if (ordenTipo.value === "2") {
                                  //Ordenado por nro renglon
                                  pedidoActualRenglones?.sort(
                                    (r1: any, r2: any) =>
                                      r2.pedidoRenglonPK.pedidoNroRenglon -
                                      r1.pedidoRenglonPK.pedidoNroRenglon
                                  );
                                }
                                setOrdenRenglonesPedido(ordenTipo);
                              }}
                            />
                            <div className="d-flex">
                              <Select
                                // className="mb-4 selectpage border me-1"
                                value={pageSizeOptions.find(
                                  (option) => option.value === pageSize
                                )}
                                onChange={handleItemsPerPageChange}
                                options={pageSizeOptions}
                                placeholder="Selecciona cantidad por página"
                              />
                            </div>
                          </div>
                          <div className="product-details table-responsive text-nowrap">
                            <Table className="table table-bordered table-hover mb-0 text-nowrap paso12">
                              <thead>
                                <tr>
                                  <th className="text-start ">Producto</th>
                                  <th className="w-150">Cantidad</th>
                                  <th>Precio</th>
                                  <th>Stock</th>
                                  <th>Subtotal</th>
                                  <th>Eliminar</th>
                                </tr>
                              </thead>
                              {renderCuerpoRenglonesPedido()}
                            </Table>

                            <div className="text-wrap">
                              <div className="example">
                                <Pagination className="mb-0">
                                  <Pagination.Item
                                    className="page-item"
                                    onClick={() =>
                                      handleClickPage(currentPage - 1)
                                    }
                                    disabled={currentPage === 1}
                                  >
                                    <i className="icon ion-ios-arrow-back"></i>
                                  </Pagination.Item>

                                  {[...Array(endPage - startPage + 1)].map(
                                    (_, index) => (
                                      <Pagination.Item
                                        className="page-item"
                                        key={startPage + index}
                                        active={
                                          startPage + index === currentPage
                                        }
                                        onClick={() =>
                                          handleClickPage(startPage + index)
                                        }
                                      >
                                        {startPage + index}
                                      </Pagination.Item>
                                    )
                                  )}

                                  <Pagination.Item
                                    className="page-item"
                                    onClick={() =>
                                      handleClickPage(currentPage + 1)
                                    }
                                    disabled={currentPage === totalPages}
                                  >
                                    <i className="icon ion-ios-arrow-forward"></i>
                                  </Pagination.Item>
                                </Pagination>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end align-items-center">
                              {" "}
                              <span className="heading align-right mr-20 mt-2">
                                Subtotal
                              </span>{" "}
                              <span className="total tx-20 font-weight-bold align-right">
                                {"$ "}
                                {importeTotal()}
                              </span>{" "}
                            </div>
                            <div>
                              Los stock son aproximados, debido a todos los
                              procesos que ocurren simultaneamente
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  {/* <!-- row closed --> */}
                </Tab>
                <Tab eventKey="tPendientes" title="Pendientes Anteriores">
                  {/* <!-- row opened --> */}
                  <Row className="paso15">
                    <Col>
                      <Card>
                        <Card.Body>
                          <div className="table-responsive-container">
                            <TablaDataBasica
                              columns={pendienteColumns}
                              data={pendientes}
                              mostrarCantidadRgistros={false}
                              mostrarFiltro={false}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  {/* <!-- row closed --> */}
                </Tab>
                <Tab eventKey="tEnviar" title="Enviar Pedido">
                  <Col lg={12} md={12}>
                    <Card>
                      <Card.Body>
                        {/* <div className="main-content-label mg-b-5 text-center">Detalles de envio</div> */}
                        <Row>
                          <Col
                            md={10}
                            lg={8}
                            xl={6}
                            className="mx-auto d-block"
                          >
                            <Card.Body className="card pd-20 pd-md-40 shadow-none">
                              <Card className=" bg-danger-gradient text-white">
                                <Card.Body>
                                  <Row>
                                    <div className="col-3">
                                      <div className="icon1 mt-2 text-center">
                                        <i className="fe fe-shopping-cart tx-40"></i>
                                      </div>
                                    </div>
                                    <div className="col-9">
                                      <div className="mt-0 text-center">
                                        <span className="text-white">
                                          Total Renglones
                                        </span>
                                        <h2 className="text-white mb-0">{`${pedidoActualRenglones.length
                                          } producto${pedidoActualRenglones.length > 1
                                            ? "s"
                                            : ""
                                          }`}</h2>
                                      </div>
                                    </div>
                                  </Row>
                                </Card.Body>
                              </Card>
                              <FormGroup className="">
                                <Form.Label>Transporte</Form.Label>
                                <Select
                                  onChange={eleccionTransporte}
                                  options={listTransportesDispon()}
                                  classNamePrefix="formselect"
                                  className="paso18"
                                  placeholder="Seleccione su transporte"
                                  isSearchable={false}
                                  //defaultValue={booleanToSelect(user.clienteConfiguracionMostrarIVA)}
                                  id="cp-mc"
                                />
                                {/* //Aca aprendimos la forma de ocultar cosas  con el atributo d-none
                                    pero vienen formas muy zarpadas de ocultar otras cosas
                                    https://react.spruko.com/nowa-ts/preview/Utilities/display/
                                */}
                                {/* <i className="typcn typcn-calendar-outline tx-24 lh--9 op-6"></i> */}
                                <Form.Label
                                  className={` ${requiereFechaRetiro ? "" : " d-none"
                                    }`}
                                >
                                  Fecha retiro
                                </Form.Label>
                                <Form
                                  className={`form-control ${requiereFechaRetiro ? "" : "d-none"
                                    }`}
                                  noValidate
                                >
                                  <TextField
                                    id="datetime-local"
                                    type="datetime-local"
                                    defaultValue={(() => {
                                      const now = new Date();
                                      const currentHours = now.getHours();
                                      const currentMinutes = now.getMinutes();
                                      if (
                                        currentHours < 7 ||
                                        (currentHours === 16 &&
                                          currentMinutes > 30) ||
                                        currentHours > 16
                                      ) {
                                        now.setHours(16, 30, 0, 0);
                                      }
                                      return new Date(
                                        now.getTime() -
                                        now.getTimezoneOffset() * 60000
                                      )
                                        .toISOString()
                                        .slice(0, 16);
                                    })()}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      const selectedDate = new Date(
                                        e.target.value
                                      );
                                      const currentDate = new Date();
                                      const dayOfWeek = selectedDate.getDay();
                                      // Verificar si el día seleccionado es sábado o domingo
                                      if (dayOfWeek === 6 || dayOfWeek === 0) {
                                        setAlertDialog({
                                          ...alertDialog,
                                          visible: true,
                                          cabecera:
                                            "No puedes seleccionar un fin de semana.",
                                          severity: "warning",
                                          texto: "",
                                        });
                                        e.target.value = valFechaRetiro;
                                        return;
                                      }
                                      // Verificar si el día seleccionado es lunes y el día actual es viernes
                                      if (
                                        dayOfWeek === 1 &&
                                        currentDate.getDay() === 5
                                      ) {
                                        setAlertDialog({
                                          ...alertDialog,
                                          visible: true,
                                          cabecera:
                                            "No puedes seleccionar una fecha de retiro que sea menor a 48 horas hábiles.",
                                          severity: "warning",
                                          texto: "",
                                        });
                                        e.target.value = valFechaRetiro;
                                        return;
                                      }
                                      // Calcular la fecha mínima permitida para realizar un pedido
                                      const minOrderDate = new Date(
                                        currentDate.getTime() +
                                        48 * 60 * 60 * 1000
                                      );
                                      // Verificar si la fecha seleccionada está dentro de las próximas 48 horas
                                      if (selectedDate < minOrderDate) {
                                        setAlertDialog({
                                          ...alertDialog,
                                          visible: true,
                                          cabecera:
                                            "No puedes seleccionar una fecha de retiro que sea menor a 48 horas hábiles.",
                                          severity: "warning",
                                          texto: "",
                                        });
                                        e.target.value = valFechaRetiro;
                                        return;
                                      }
                                      // Verificar si la hora seleccionada está fuera del rango de 7:00 a 16:30 hs en hora local
                                      const selectedHours =
                                        selectedDate.getHours();
                                      const selectedMinutes =
                                        selectedDate.getMinutes();
                                      if (
                                        selectedHours < 7 ||
                                        (selectedHours === 16 &&
                                          selectedMinutes > 30) ||
                                        selectedHours > 16
                                      ) {
                                        setAlertDialog({
                                          ...alertDialog,
                                          visible: true,
                                          cabecera:
                                            "No puedes seleccionar una hora fuera del rango de 7:00 a 16:30 hs.",
                                          severity: "warning",
                                          texto: "",
                                        });
                                        // Establecer la hora a las 16:30, manteniendo la fecha seleccionada
                                        let updatedDate = new Date(
                                          selectedDate
                                        );
                                        updatedDate.setHours(16, 30, 0, 0);
                                        e.target.value = new Date(
                                          updatedDate.getTime() -
                                          updatedDate.getTimezoneOffset() *
                                          60000
                                        )
                                          .toISOString()
                                          .slice(0, 16);
                                        return;
                                      }
                                      valFechaRetiro = e.target.value;
                                    }}
                                  />
                                </Form>
                                <p></p>
                                <Form.Control
                                  className={`form-control ${requiereDetalleRetiro ? "" : " d-none"
                                    }`}
                                  type="text"
                                  id="inputDetalleComiusionista"
                                  placeholder="Nombre comisionista"
                                  onChange={(e) => {
                                    const selectedDate = new Date(
                                      e.target.value
                                    );
                                    const currentDate = new Date();
                                    // const diffInMilliseconds = Math.abs(selectedDate - currentDate);
                                    // const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
                                    // if (diffInHours < 48) {
                                    //   alert("No puedes seleccionar una fecha de retiro que sea menor a 48 horas.");
                                    // } else {
                                    //   valFechaRetiro = e.target.value;
                                    // }
                                  }}
                                />
                                <Form.Label>Forma envio</Form.Label>
                                <Select
                                  onChange={(e) => (valFormaEnvio = e.value)}
                                  ref={refFormaEnvio}
                                  options={listFormaEnvio()}
                                  isSearchable={false}
                                  className="paso19"
                                  //defaultValue={booleanToSelect(user.clienteConfiguracionMostrarIVA)}
                                  placeholder="Seleccione forma envio"
                                  id="cp-mpci"
                                />
                                <Form.Label>Condicion venta</Form.Label>
                                <Select
                                  ref={refCondicionVenta}
                                  onChange={(e) => (valFormaVenta = e.value)}
                                  options={listCondicionesVenta()}
                                  isSearchable={false}
                                  className="paso20"
                                  //defaultValue={booleanToSelect(user.clienteConfiguracionMostrarIVA)}
                                  placeholder="Seleccione condicion venta"
                                  id="cp-mpci"
                                />
                                <Form.Label>
                                  Nro Orden Compra (Opcional)
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className="form-control paso21"
                                  onChange={(e) =>
                                    (valOrdenCompra = e.target.value)
                                  }
                                  id="inputOc"
                                  placeholder="Numero Orden Compra (Opcional)"
                                />
                                <Form.Label>
                                  IMPORTANTE: El stock será reservado
                                  definitivamente una vez que el pedido corra
                                  los procesos administrativos. Esto puede tomar
                                  alrededor de 12 horas hábiles
                                </Form.Label>
                                <Button
                                  variant=""
                                  type="submit"
                                  className="btn btn-primary btn-block paso22"
                                  onClick={enviarPedido}
                                >
                                  Enviar pedido
                                </Button>
                              </FormGroup>
                            </Card.Body>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Tab>
              </Tabs>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiPedidoActual;
