import { FormGroup } from '@mui/material';
import Select from "react-select";
import React, { useEffect, useState } from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Button, Card, Col, Form, Row, Spinner, Pagination } from "react-bootstrap";
import { useNavigate, useSearchParams } from 'react-router-dom';
import CardGrupo from '../../../Card Grupos/CardGrupo';
import apiFc from '../../../../services/APIFc';
import { AlertDialogInterface, SelectOption } from '../../../common/Interfaces';
import { useSelector } from 'react-redux';
import { decryptData } from '../../../../functions/cryptoUtils';
import ExtraLargeModal from '../../../common/ExtraLargeModal';



const CatalogoListado = () => {
  const navigate = useNavigate();
  const user = useSelector((store: any) => decryptData(store.authreducer.user));
  const [queryParameters] = useSearchParams();
  const [items, setItems] = useState([]);
  const [filtroLocal, setFiltroLocal] = useState('');
  const [selectedGroup, setSelectedGroup] = useState<any>({});
  const [articulosGrupo, setArticulosGrupo] = useState([]);
  const [showDetalle, setShowDetalle] = useState(false);
  const [alertDialog, setAlertDialog] = useState<AlertDialogInterface>({
    id: "1",
    visible: false,
    severity: 'success', // severity="error" / "warning" / "info" / "success"
    timeOut: 2000, // severity="error" / "warning" / "info" / "success"
    cabecera: "Agregado!",
    texto: "el producto fue agregado.",
  });

  const [lineaId, setLineaId] = useState(queryParameters.get("lineaId"))
  const [abc, setAbc] = useState(queryParameters.get("abc"))
  const [filtro, setFiltro] = useState(queryParameters.get("filtro"))
  const [marcaId, setMarcaId] = useState(queryParameters.get("marcaId"))
  const [isFiltroCollapseds, setFiltroCollapseds] = useState<boolean>(true)
  const [isFiltroAbcCollapseds, setIsFiltroAbcCollapseds] = useState<boolean>(true)
  const marcas = useSelector((store: any) => decryptData(store.principalreducer.marcas) || []);
  const rubros = useSelector((store: any) => decryptData(store.principalreducer.rubros) || []);
  const [showModal, setShowModal] = useState(false);
  const [showingSuggest, setShowingSuggest] = useState(false);
  const [isAbcSearch, setIsAbcSearch] = useState(false);

  const handleOpenModal = (articulo: any) => {
    setSelectedGroup(articulo);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedGroup({});
  };

  const [paginaActual, setPaginaActual] = useState(1);
  const [selectedLetter, setSelectedLetter] = useState<string | null>(null);

  const handleClickLetra = (letra: any) => {
    setAbc(letra);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handlePaginaAnterior = () => {
    const abecedarioPaginadorList = abecedarioPaginador();
    const letraActualIndex = abecedarioPaginadorList.findIndex(letra => letra.value === abc);
    // Verificar si no estamos en la primera página
    if (letraActualIndex > 0) {
      const letraAnterior = abecedarioPaginadorList[letraActualIndex - 1].value;
      setAbc(letraAnterior);
      // Actualizar la página actual solo si cambiamos de letra
      setPaginaActual(paginaActual - 1);
    } else {
      // Si estamos en la primera letra, ir a la última letra
      const totalPaginas = abecedarioPaginadorList.length;
      const ultimaLetra = abecedarioPaginadorList[totalPaginas - 1].value;
      setAbc(ultimaLetra);
      // Actualizar la página actual solo si cambiamos de letra
      setPaginaActual(totalPaginas);
    }
  };

  const handlePaginaSiguiente = () => {
    const abecedarioPaginadorList = abecedarioPaginador();
    const letraActualIndex = abecedarioPaginadorList.findIndex(letra => letra.value === abc);
    const totalPaginas = abecedarioPaginadorList.length;
    // Verificar si no estamos en la última página
    if (letraActualIndex < totalPaginas - 1) {
      const siguienteLetra = abecedarioPaginadorList[letraActualIndex + 1].value;
      setAbc(siguienteLetra);
      setPaginaActual(paginaActual + 1);
    } else {
      // Si estamos en la última página, ir a la primera página
      setAbc(abecedarioPaginadorList[0].value);
      setPaginaActual(1);
    }
  };
  const abecedarioPaginador = (): SelectOption[] => {
    let abecedarioPaginador: SelectOption[] = [];
    const letrasAbecedario = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (abc !== null) {
      const letraIndex = letrasAbecedario.indexOf(abc);
      if (letraIndex >= 0) {
        const letraActual = letrasAbecedario.charAt(letraIndex);
        abecedarioPaginador.push({ label: letraActual, value: letraActual });
        const siguienteIndex = letraIndex + 1;
        if (siguienteIndex < letrasAbecedario.length) {
          const siguienteLetra = letrasAbecedario.charAt(siguienteIndex);
          abecedarioPaginador.push({ label: siguienteLetra, value: siguienteLetra });
        }
      }
    }
    return abecedarioPaginador;
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 530);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 530);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  let rubrosLineas = (): SelectOption[] => {
    let rubroslineas: SelectOption[] = [];
    rubros.map((rubro: any) =>
      rubro.articuloRubroLineaList.map((linea: any) => {
        let opcion: SelectOption = { value: '', label: '' }
        opcion.value = linea.id
        opcion.label = `${rubro.detalle} - ${linea.detalle}`
        rubroslineas.push(opcion)
      })
    )
    return rubroslineas;
  };
  let abecedario = (): SelectOption[] => {
    let abecedario: SelectOption[] = [];
    let alphabet: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    alphabet.split('').map((letter: string) => {
      let opcion: SelectOption = { value: '', label: '' }
      opcion.value = letter
      opcion.label = letter

      abecedario.push(opcion)
    }
    )
    return abecedario;
  };
  let marcasOpciones = (): SelectOption[] => {
    let marcasList: SelectOption[] = [];
    marcas.map((marcam: any) => {
      let opcion: SelectOption = { value: '', label: '' }
      opcion.value = marcam.id
      opcion.label = marcam.descripcion
      marcasList.push(opcion)
    }
    )
    return marcasList;
  };

  let handleFiltroCollapsable = () => {
    setFiltroCollapseds(!isFiltroCollapseds);
  };

  const handleFiltroAbc = () => {
    setAbc('A');
    setMarcaId(null);
    setFiltro(null);
    setLineaId(null);
    fetchData();
    setIsFiltroAbcCollapseds(!isFiltroAbcCollapseds);
    setIsAbcSearch(true)
  };

  // useEffect(() => { }, [isFiltroCollapseds]);
  useEffect(() => {
    fetchData();
  }, [paginaActual]);

  useEffect(() => { }, [items]);


  useEffect(() => {
    fetchData();

  }, [lineaId, marcaId, abc, filtro, isAbcSearch]);

  useEffect(() => {
    setLineaId(queryParameters.get("lineaId"))
    setFiltro(queryParameters.get("filtro"))
    setMarcaId(queryParameters.get("marcaId"))
    fetchData();
  }, [queryParameters]);

  useEffect(() => {
    if (selectedGroup.articuloGrupoId > 0) {
      try {
        apiFc.get(`/articuloGrupos/${selectedGroup.articuloGrupoId}/articulos`).then(res => {
          setArticulosGrupo(res.data)
          setShowDetalle(true)
        })
      } catch (error) { }
    }
  }, [selectedGroup])

  const fetchData = () => {
    const fetchDataParams = {
      lineaId: lineaId !== null ? lineaId : undefined,
      filtro: filtro !== null ? filtro : undefined,
      marcaId: marcaId !== null ? marcaId : undefined,
      abc: abc !== null ? abc : undefined,
      isAbcSearch: isAbcSearch !== null ? isAbcSearch : undefined,

    };

    setShowingSuggest(false);

    if (lineaId !== null) {
      apiFc.get(`/web/articulos/rubros/lineas/${fetchDataParams.lineaId}/articulogrupos?page=0&rows=1000`)
        .then((res) => {
          setItems(res.data.content);
          setFiltroLocal('');
          window.scrollTo(0, 0);
          setShowingSuggest(false);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }

    if (filtro !== null) {
      apiFc.get(`/articuloGrupos/page?filter=${fetchDataParams.filtro}&page=0&rows=1000`)
        .then(res => {
          if (res.data.content.length === 0) {

            apiFc.get(`/articuloGrupos/page/byWords?filter=${fetchDataParams.filtro}&rows=100`)
              .then(ress => {
                setShowingSuggest(true);
                setItems(ress.data.content);
                window.scrollTo(0, 0);
                setIsAbcSearch(false)
              })
              .catch((error) => {
                console.error('Error fetching suggestions:', error);
              });
          } else {
            setItems(res.data.content);
            setFiltroLocal('');
            window.scrollTo(0, 0);
            setShowingSuggest(false);
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }

    if (marcaId !== null) {
      apiFc.get(`/web/articulos/marcas/${fetchDataParams.marcaId}/articulogrupos?page=0&rows=1000`)
        .then(res => {
          setItems(res.data.content);
          setFiltroLocal('');
          window.scrollTo(0, 0);
          setShowingSuggest(false);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }

    if (abc !== null) {
      apiFc.get(`/articuloGrupos/page/startWhith?filter=${fetchDataParams.abc}&page=0&rows=1000`)
        .then(res => {
          setItems(res.data.content);
          setFiltroLocal('');
          window.scrollTo(0, 0);
          setShowingSuggest(false);
          setIsAbcSearch(true)
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  };


  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Listado Articulos
          </span>
          <ExtraLargeModal
            show={showModal}
            handleClose={handleCloseModal}
            selectedGroup={selectedGroup}
            alertDialog={alertDialog}
            // setAlertDialog={setAlertDialog}
            windowWidth={windowWidth}
          />
        </div>
      </div>
      {/* <!-- /breadcrumb --> */}
      <Row className="row-sm mg-t-10">
        <Col>
        <Card style={{ position: "sticky", top: "0", zIndex: 1000 }}>
  <Card.Body className="p-2">
    <Row className="mg-r-2 mg-l-2">
      <Col md={12} sm={9} xs={12}>
        <div className="input-group mg-t-5">
          <Form.Control
            type="text"
            className="form-control"
            placeholder="Buscar ..."
            onFocus={(e) => e.target.select()}
            onKeyDown={(e: any) => {
              setFiltro(e.target.value || "");
              if (e.code === "Enter") {
                setMarcaId(null);
                setLineaId(null);
                setAbc(null);
                fetchData();
                e.target.blur();
              }
            }}
          />
          <span className="input-group-append">
            <Button
              variant=""
              className="btn btn-primary"
              type="button"
              onClick={() => {
                setMarcaId(null);
                setLineaId(null);
                setAbc(null);
                fetchData();
              }}
            >
              Buscar
            </Button>
          </span>
        </div>
      </Col>
      <Col md={12} sm={3} xs={12} className="mg-t-5">
        <Button
          variant=""
          className="btn-primary btn-block"
          role="button"
          onClick={() => handleFiltroCollapsable()}
        >
          Navegar
        </Button>
      </Col>
      <Col md={12} sm={3} xs={12} className="mg-t-5">
        <Button
          variant=""
          className="btn-primary btn-block"
          role="button"
          onClick={() => handleFiltroAbc()}
        >
          Navegar por abecedario
        </Button>
      </Col>
    </Row>
    <Col>
      {!isFiltroCollapseds ? (
        <FormGroup className="form-group mt-2">
          <Form.Label className="form-label">Linea</Form.Label>
          <Select
            placeholder={"Seleccione"}
            defaultValue={rubrosLineas().find((rubro) => rubro.value === lineaId || undefined)}
            options={rubrosLineas()}
            onChange={(linea: any) => {
              setLineaId(linea.value);
              setMarcaId(null);
              setFiltro(null);
              setAbc(null);
              fetchData();
              setFiltroCollapseds(!isFiltroCollapseds);
            }}
          />
          <Form.Label className="form-label">Marcas</Form.Label>
          <Select
            placeholder={"Seleccione"}
            defaultValue={marcasOpciones().find((marca) => marca.value == marcaId || undefined)}
            options={marcasOpciones()}
            onChange={(marca: any) => {
              setMarcaId(marca.value);
              setAbc(null);
              setFiltro(null);
              setLineaId(null);
              fetchData();
              setFiltroCollapseds(!isFiltroCollapseds);
            }}
          />
        </FormGroup>
      ) : null}
    </Col>
    <Col>
      {!isFiltroAbcCollapseds ? (
        <FormGroup className="form-group mt-2">
          <Form.Label className="form-label">Abecedario</Form.Label>
          <Select
            placeholder={"Seleccione"}
            defaultValue={abecedario().find((letra) => letra.value == abc || undefined)}
            options={abecedario()}
            onChange={(abc: any) => {
              setAbc(abc.value);
              setMarcaId(null);
              setFiltro(null);
              setLineaId(null);
              fetchData();
              setIsAbcSearch(true);
            }}
          />
        </FormGroup>
      ) : null}
    </Col>
  </Card.Body>
</Card>

        </Col>
        <Col xl={9} lg={8} md={12}>
          <Row className="row-sm ">
            {items.length === 0
              ?
              <Row className="wd-100p">
                <div className="text-center mg-b-20 wd-100p">
                  <Spinner
                    animation="border"
                    className="spinner-border "
                    role="status"
                  >
                    <span className="sr-only">Cargando...</span>
                  </Spinner>
                </div>
              </Row>
              :
              <>
                {showingSuggest && !isAbcSearch && (
                  <Row className="wd-100p">
                    <label className="fade alert fade show alert-dismissible alert-default alert show wd-100p flex-column">
                      <p className={`${isSmallScreen ? 'list-inline-item' : 'justify-content-center d-flex '}`}>
                        No existen resultados para <b>"{filtro}"</b>. Pruebe con otro valor.
                      </p>
                      <h4 className="justify-content-center d-flex">
                        Basado en tu búsqueda, mirá las sugerencias que tenemos para vos:
                      </h4>
                    </label>
                  </Row>
                )}
                {items.filter((item: any) => filtroLocal || item.articuloGrupoDescripcion.toLowerCase().includes(filtroLocal.toLowerCase()))
                  .map((articuloGrupo: any, i) => {
                    return (
                      <Col sm={6} xl={4} xxl={3} lg={6} md={6} key={articuloGrupo.id}>
                        <CardGrupo articuloGrupo={articuloGrupo} key={articuloGrupo.id}
                          onClick={() => {
                            setSelectedGroup(articuloGrupo);
                            handleOpenModal(articuloGrupo);
                          }}
                        />
                      </Col>
                    );
                  })}
              </>
            }
          </Row>
        </Col>
      </Row>
      {abc && (
        <Pagination className="pagination justify-content-center">
          <Pagination.Prev onClick={handlePaginaAnterior} />
          {abecedarioPaginador().map((letra, index) => (
            <Pagination.Item
              key={index}
              active={abc === letra.value}
              onClick={() => handleClickLetra(letra.value)}
            >
              {letra.label}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={handlePaginaSiguiente} />
        </Pagination>
      )}
    </div>

  );
};

export default CatalogoListado;
